import React, { Component } from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Checkbox,
  Divider,
  Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./FilterWeb.css";
import { TFilter } from "../../blocks/Cache2/src/Cache2Controller.web";
import get from "lodash/get";
import find from 'lodash/find'
import { size } from 'lodash'

// import FilteroptionsController, {
//   Props,
//   configJSON
// } from "./FilteroptionsController";
interface Props {
  handleClose: () => void;
  handleClear: any;
  //  handleChecks : any,
  //  selected : any,
  isDark: any;
  handleApplyFilterBtn: () => void;
  //  filterOthers : any;
  selectedFilter: any;
  otherDays: string;
  updateFilter: (data: any) => void;
  // disableKey: Array<string>;
  checkDateBetween: (date: string, days: number) => boolean;
  tabState: any;
  resetData?: Function
}

interface S {
  value: any,
}

interface SS {
  id: any;
}

const filterKey = {
  author: 'author',
  last7days: 'last7days',
  last30days: 'last30days',
  last60days: 'last60days',
  daterange: 'daterange',
  recipient: 'recipient',
  hasAttachement: 'hasAttachement'
}
const filterItems = [
  {
    key: filterKey.last7days,
    title: 'Last 7 days',
    value: 'days',
    days: 7
  },
  {
    key: filterKey.last30days,
    title: 'Last 30 days',
    value: 'days',
    days: 30
  },
  {
    key: filterKey.last60days,
    title: 'Last 60 days',
    value: 'days',
    days: 60
  },
  {
    key: filterKey.hasAttachement,
    title: 'Has attachment',
    value: 'has_attachment',
    days: 0,
    has_attachment: true
  }
]
export default class FilterWeb extends Component<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: ''
    }
  }

  handleInputChange = (e: any) => {
    const value = e.target.value
    
    
    this.setState({ value: e.target.value })
    this.props.updateFilter({

      key: 'otherDays',
      value: 'days',
      days: value

    })
  }

  render() {
    const isDark = this.context.theme === "dark";
    const themeStyles = isDark ? darkStyles : lightStyles;
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
    });
    const attributeKeys = {
      sendDate: "sent_dt",
      download: "downloaded_date",
    };

    const tabkeys = {
      download: "Downloaded",
    };


    const selectedFilter = this.props.selectedFilter || []


    /* istanbul ignore next */
    return (
      //Merge Engine DefaultContainer

      <ThemeProvider theme={theming}>
        <Container
          data-test-id="filterOptionsContainer"
          style={{
            width: "48%",
            maxWidth: "400px",
            position: "absolute",
            top: "10%", 
            left: "50%",
            transform: "translateX(-50%)",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "0px",
            zIndex: 9999,
            backgroundColor: isDark ? "black" : "white",
            color: isDark ? "white" : "black",
          }}
          className="filterContainerMain"
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px",
              alignItems: "center",
            }}
          >
            <Typography style={webStyle.filter_title_typo}>
              Filter By
            </Typography>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={this.props.handleClear}
              data-test-id="clearFilter"
            >
              Clear
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gridGap: "8px",
            }}
          >
            {filterItems.map((ele, i) => (
              <Box
                key={ele.title}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                }}
                onClick={() =>
                  this.props.updateFilter(ele)
                }
                data-test-id="options"
              >
                <Typography style={webStyle.filter_typo}>
                  {ele.title}
                </Typography>
                {
                  find(selectedFilter, (filter: any) => filter.key === get(ele, 'key')) ?
                    <Checkbox
                      style={{
                        position: "absolute",
                        right: 3,
                        ...themeStyles.checkbox_color,
                        color: this.props.isDark ? "grey" : "black",
                      }}
                      size="small"
                      value={ele.title}
                      onChange={() => this.props.updateFilter(ele)}
                      // checked={Boolean(this.props.selectedFilter.get(ele.title))}
                      checked={true}
                    />
                    :
                    <Checkbox
                      style={{
                        position: "absolute",
                        right: 3,
                        ...themeStyles.checkbox_color,
                        color: this.props.isDark ? "grey" : "black",
                      }}
                      size="small"
                      value={ele.title}
                      onChange={() => this.props.updateFilter(ele)}
                      // checked={Boolean(this.props.selectedFilter.get(ele.title))}
                      checked={false}
                    />
                }
              </Box>
            ))}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
              }}
            >
              <label style={{ ...webStyle.filter_typo, marginRight: "5px" }}>
                Other days :{" "}
              </label>


              <input
                type="number"
                // disabled={this.props.disableKey.includes("otherDays")}
                value={this.props.otherDays}
                onChange={this.handleInputChange}
                // onChange={(e) => {
                //   const value = e.target.value;
                //   console.log('====aaannn', value)
                //   // this.props.updateFilter({

                //   //   key: 'otherDays',
                //   //   title: 'otherDays',
                //   //   value: 'days',
                //   //   days: value,
                //   // })
                // }}
                style={{
                  backgroundColor: this.props.isDark ? "black" : "#fcfafa",
                  color: this.props.isDark ? "white" : "black",
                  width: "70px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "center",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gridGap: "10px",
                paddingLeft: "10px",
                paddingBottom: "20px",
              }}
            >
              <button
                style={{
                  marginRight: "0.5em",
                  marginLeft: "0.6em",
                  backgroundColor: "yellow",
                  border: "1px solid yellow",
                }}
                className="btn"
                onClick={this.props.handleApplyFilterBtn}
                data-test-id="applyButton"
              >
                Apply
              </button>
              <button
                className="btn"
                style={{
                  backgroundColor: this.props.isDark ? "grey" : "#fcfafa",
                  color: "#aba4a4",
                  border: "none",
                }}
                onClick={this.props.handleClose}
              >
                Cancel
              </button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

const lightStyles = {
  containerBackground: {
    backgroundColor: "white",
    color: "black",
  },
  checkbox_color: {
    color: "black",
  },
};
const darkStyles = {
  containerBackground: {
    backgroundColor: "black",
    border: "1px solid white",
    color: "white",
  },
  checkbox_color: {
    color: "yellow",
  },
};
const webStyle = {
  filter_title_typo: {
    fontSize: "15px",
  },
  filter_typo: {
    fontSize: "14px",
    cursor: "pointer",
  },
  apply_btn: {
    backgroundColor: "#F5F166",
    color: "#656565",
    borderRadius: "5px",
  },
  cancel_btn: {
    backgroundColor: "white",
    color: "#656565",
    borderRadius: "5px",
  },
};
// Customizable Area End
