import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Input,
  Typography,
  createStyles,
  ThemeProvider,
  createTheme,
  Paper, Modal
} from "@material-ui/core";
import {
  imgLogoLight,
  imgLogoDark,

} from "./assets";

import "./EmailAccountLoginWeb.css"
import EmailAccountLoginWebController, { Props } from "./EmailAccountLoginWebController.web";
const darkStyles = {
  inputLabel: {
    color: "white",
  },
  input: {
    color: "white",
    "& input::placeholder": {
      color: "white",
    },
  },
  button: {
    color: "black",
  },
  background: {
    backgroundColor: "#242424",
  },
};
const lightStyles = {
  inputLabel: {
    color: "black",
  },
  input: {
    color: "black",
  },
  button: {
    color: "white",
  },
  background: {
    backgroundColor: "#ffffff",
  },
};
const styles: any = createStyles({
  container: {
    marginTop: "15vh",
  },
  title: {
    fontWeight: "bold",
  },
  subTitle: {
    color: "grey",
    fontSize: "14px",
    marginTop: "5px",
  },
  inputLabel: {
    fontSize: "13px",
    paddingBottom: "7px",
  },

  input: {
    border: "1.5px solid grey",
    padding: "4px 10px",
    borderRadius: "5px",
  },
  button: {
    textTransform: "none",
    cursor: "pointer",
  },
  paper: {
    height: "100%",
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginWebController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;

    const theme = createTheme({
      palette: {
        primary: { main: "#001892" },
        secondary: { main: "#f5f166" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#202020" : "#fffff",
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <Paper style={{ ...styles.paper }} data-testid="testIDContainer">
          <Container maxWidth="sm" style={{ ...styles.container, marginBottom: "30px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <Box style={{ marginBottom: "30px", marginTop: "0px" }}>
                {/* <Box style={{scrollbarWidth: "none",marginBottom:"30px", marginTop:"10px"}}> */}
                {/* <Box> */}
                <img
                  src={
                    this.context.theme === "dark"
                      ? imgLogoDark : imgLogoLight
                  }
                  alt="company_logo"
                  width="100px"
                  data-testId="light-dark-img"
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                <Typography component="div" style={styles.title}>
                  {this.state.welcomeBack}
                </Typography>
                <Typography component="div" style={styles.subTitle}>
                  {this.state.welcomeBackSubLabel}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "72%",
                  margin: "auto",
                  padding: "30px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  marginTop: "20px",
                  borderRadius: "5px",
                  ...themeStyle.background,
                }}
              >
                <Box>
                  <InputLabel
                    style={{ ...styles.inputLabel, ...themeStyle.inputLabel }}
                  >
                    {this.state.placeHolderEmail}
                  </InputLabel>
                  <Input
                    type="email"
                    data-test-id="txtInputEmail"
                    placeholder={this.state.placeHolderEmail}
                    fullWidth={true}
                    value={this.state.email}
                    disableUnderline={true}

                    onChange={(e) => this.setEmail(e.target.value)}
                    onKeyDown={this.loginOnKeyPress}
                    style={{ ...styles.input, ...themeStyle.input }}
                  />
                </Box>
                <Box sx={{ padding: "20px 0px",position: "relative"}}>
                  <InputLabel
                    style={{ ...styles.inputLabel, ...themeStyle.inputLabel }}
                  >
                    {this.state.inputLabel2}
                  </InputLabel>
                  <Input
                    data-test-id="txtInputPassword"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={"*************"}
                     fullWidth={true}
                     disableUnderline={true}
                    onChange={(e) => this.handlePasswordChange(e.target.value)}
                    onKeyDown={this.loginOnKeyPress}
                    style={{ ...styles.input, ...themeStyle.input }}
                   
                  />
                
                  {this.state.showPassword ?
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6"  style={{ position: 'absolute', right: '1em', top: '58%', transform: 'translateY(-50%)' }} data-test-id="svgBtn1" onClick={this.togglePasswordVisibility}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6"  style={{ position: 'absolute', right: '1em', top: '58%', transform: 'translateY(-50%)' }} data-test-id="svgBtn1" onClick={this.togglePasswordVisibility}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  }
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    data-testId="btnEmailLogIn"
                    variant="contained"
                    color={
                      this.context.theme === "dark" ? "secondary" : "primary"
                    }
                    fullWidth

                    onClick={this.handleClick}

                    style={{ ...styles.button, ...themeStyle.button }}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Paper>
        <Modal
        data-test-id="modalTest"
        open={this.state.open}
        onClose={()=>this.setState({open:false})}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom:"6px"}}>
           {this.state.errmsg}
          </Typography>
          <Box style={{display:"flex", justifyContent : "center", paddingTop:"2px"}}>
          <button data-test-id="modalOnclick" onClick={()=>this.setState({open:false})} style={{backgroundColor:"rgb(33, 150, 243)", color:"white",padding:"8px", border:"none"}}>OK</button>
          </Box>
        </Box>
      </Modal>
      </ThemeProvider>
    );
  }
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
// Customizable Area End
