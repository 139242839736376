
import {
    StyleSheet,
} from "react-native";

export const palette = {
    dark: {
        background: '#202020',
        title: '#FFFFFF',
        subTitle: "#A1A1A1",
        iconColor: '#F7F000',
        hightlight: '#3c3a3a',
    },
    light: {
        background: '#FFFFFF',
        title: '#171601',
        subTitle: "#5C5D5F",
        iconColor: '#4D5DB4',
        hightlight: '#F0F1F5',
    }
}

export const darkStyles = StyleSheet.create({
    background: {
        backgroundColor: palette.dark.background,
    },
    title: {
        color: palette.dark.title,
    },
    subTitle: {
        color: palette.dark.subTitle,
    },
    icon: {
        color: palette.dark.iconColor,
    },
    highLight: {
        backgroundColor: palette.dark.hightlight
    },
    buttonBg: {
        backgroundColor: palette.dark.iconColor
    },
});

export const lightStyles = StyleSheet.create({
    background: {
        backgroundColor: palette.light.background,
    },
    title: {
        color: palette.light.title,
    },
    subTitle: {
        color: palette.light.subTitle,
    },
    icon: {
        color: palette.light.iconColor,
    },
    line: {
        backgroundColor: palette.light.subTitle
    },
    highLight: {
        backgroundColor: palette.light.hightlight
    },
    buttonBg: {
        backgroundColor: palette.light.iconColor
    },
});
