import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  setStorageData,
} from '../../../framework/src/Utilities';
import { ThemeContext, THEME_STORED_KEY } from '../../../framework/src/ThemeContext/ThemeProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    
  navigation: any;
  id: string;
  // Customizable Area Start
  drawer: {
    navigation: {
      toggleDrawer: Function
    },
    route: {
      name: string
    }
  };
  dataSyncPostAPI: any;
  dataSyncState :any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isDark: boolean,
  timeSync: string
  isOpen: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5WebController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  static contextType = ThemeContext;
  // declare context: React.ContextType<typeof ThemeContext>;
  
  messageId: any = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",txtSavedValue: "A",enableField: false,
      // Customizable Area Start
      isDark: false,timeSync: '30',
      isOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  toggleTheme = async () => {
    const {theme} = this.context!;

    const isDark = theme === 'dark';

    const newMode = 
    isDark ? 'light' : 'dark'
    console.log("isDark ---", theme)
    this.context.setAppMode(newMode)
    setStorageData(THEME_STORED_KEY, newMode)
   
  }
 // Customizable Area End
}
