// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";


const msalInstance = new PublicClientApplication(msalConfig);
ReactDOM.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
