"use strict";

// Customizable Area Start
const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
