import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { ThemeContext } from "../../../framework/src/ThemeContext/ThemeProvider";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

const validation = {
  email_validation_regexp:
    /^[a-zA-Z0-9.!\\#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  email: string;
  password: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  welcomeBack: string;
  welcomeBackSubLabel: string;
  inputLabel2: string;
  isLoading: boolean,
  hasCredential: boolean,
  logoutFlag: any,
  isLoadedComp: boolean,
  loginApiResponse:boolean,
  InValidInputEmail : boolean,
  InValidInputPassword : boolean,
  LoginApiCallState : boolean,
  showPassword : boolean,
  open : boolean,
  errmsg :string,
}

interface SS {
  id: any;
}

export default class EmailAccountLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiEmailAccountLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  navigationSubscription: any
  static contextType = ThemeContext;

  constructor(props: Props) {
    super(props);
    

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: true,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      welcomeBack: configJSON.welcomeBack,
      welcomeBackSubLabel: configJSON.welcomeBackSubLabel,
      inputLabel2: configJSON.inputLabel2,
      isLoading: false,
      hasCredential: false,
      logoutFlag: '',
      isLoadedComp: false,
      loginApiResponse :false,
      InValidInputEmail : false,
      InValidInputPassword : false,
      LoginApiCallState:true,
      showPassword : false,
      open : false,
      errmsg : "",
    };

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.emailReg = new RegExp(validation.email_validation_regexp);
    this.labelTitle = configJSON.btnTxtLogin;
    
  }

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };
  handlePasswordChange = (value:any) => {
    this.setState({ password: value });
  };
  loginOnKeyPress = (value :any) =>{
    if(value.keyCode === 13){
      this.handleLogin();
    }
  }
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleLogin = () => { 
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({open:true,errmsg : configJSON.validation.requiredEmailAndPassword})
      return false;
    }

    if(this.state.LoginApiCallState){
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = 
    {
      user_name: email,
      user_password: password,
    };
    console.log("detail", this.state.email , this.state.password)
    const requestLoginMessageAPi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailAccountLoginCallId = requestLoginMessageAPi.messageId;
    requestLoginMessageAPi.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage
        ),
      configJSON.loginAPiEndPointURL
    );

    requestLoginMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestLoginMessageAPi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestLoginMessageAPi.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestLoginMessageAPi.id, requestLoginMessageAPi);
    
    return true;
    }
  };
  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));
      const password = message.getData(getName(MessageEnum.LoginPassword));
      const logoutFlag = await getStorageData('logout')
      if (userName && password) {
        this.setState({
          email: userName,
          password: password,
          hasCredential: true,
          logoutFlag: logoutFlag
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiEmailAccountLoginCallId) {
        if(!responseJson.hasOwnProperty("errors")){
          this.setState({loginApiResponse: true})
          localStorage.setItem('user_name', this.state.email);
          localStorage.setItem('user_password', this.state.password);
           this.props.navigation.navigate("Cache2")
        }else{
          const r = responseJson.errors
          r.map((ele:any)=>{
            this.setState({open:true,errmsg : `${Object.values(ele)[0]}`})
          })
        }
          
          }
      else {
        console.log("login response error rrrrrrr",responseJson)
          this.parseApiErrorResponse(responseJson);
          this.sendLoginFailMessage();
        }
    }
  }
  
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  handleClick =async ()=>{
    if(!validation.email_validation_regexp.test(this.state.email) && this.state.password.length < 1){
        this.setState({InValidInputEmail : true , InValidInputPassword :true , LoginApiCallState :false})
        }
    else{
      this.setState({LoginApiCallState :true})
      
    }
    this.handleLogin()
    localStorage.setItem('user_name', this.state.email);
    localStorage.setItem('user_password', this.state.password);
    await setStorageData('logout', 'notLogout')
     
    
    };

    // handleClose = () =>{
    //   this.setState({open : false})
    // }
  

}
// Customizable Area End
