Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.deleteMethod = 'DELETE'
exports.lightThemeTitle = "Light Theme";
exports.darkThemeTitle = "Dark Theme";
exports.title = 'Setting'
exports.apiEndPointURL = '/'
exports.userJobsUrl = 'bx_block_api_search/api_searches/get_all_jobs'
exports.userDeleteJobUrl = 'bx_block_api_search/api_searches/delete_job'
// Customizable Area End