// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { ManageThemeProvider } from '../../framework/src/ThemeContext/ThemeProvider';
import { AppearanceProvider } from 'react-native-appearance';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import CacheBlock from '../../blocks/Cache2/src/Cache2.web';
import OutlookIntegration from '../../blocks/OutlookIntegration/src/OutlookIntegration.web';
import OutlookData from '../../blocks/OutlookIntegration/src/OutlookData.web';
import Setting5 from '../../blocks/Settings5/src/Settings5';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';

const routeMap = {
  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  Cache2: {
    component: CacheBlock,
    path: '/cache',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  OutlookIntegration: {
    component: OutlookIntegration,
    path: '/OutlookIntegration',
    exact: true,
  },
  OutlookData: {
    component: OutlookData,
    path: '/OutlookData',
    exact: true,
  },
  Settings5: {
    component: Setting5,
    path: '/Settings5',
    exact: true,
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <AppearanceProvider>
          <ManageThemeProvider>
            {/* <TopNav /> */}
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </ManageThemeProvider>
        </AppearanceProvider>
      </View>
    );
  }
}

export default App;
