import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Paper,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,

  // Customizable Area End
} from "@material-ui/core";
///////////////////////

//////////////////////
// Customizable Area Start
import "../../../web/src/App.css";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import {
  FilterList,
  MailOutline,
  CheckCircleOutline,
  Drafts,
  PresentToAll,
  Archive,
  Star,
  MoreHoriz,
  Reply,
  SubdirectoryArrowRight,
  ArrowDownward,
  StarOutline,
  NavigateNext,
  NavigateBefore,
} from "@material-ui/icons";
import AppHeader from "./AppHeader.web";
import moment from "moment";
import Cache2Controller from "./Cache2Controller.web";
import { Attachments } from "../../../components/src/Attachments.web";
import { darkLogo, lightLogo, lightReply, darkReply, lightForward, darkForward } from "./assets";
import Loader from "../../../components/src/Loader.web";
import Filteroptions from "../../filteritems/src/Filteroptions.web";
import SortingWeb from "../../../components/src/SortingWeb.web";
import FilterWeb from "../../../components/src/FilterWeb.web";
import "./Cache2Web.css";
import MultipleFavEmailsWeb from "../../../components/src/MultipleFavEmailsWeb.web";
import { Resizable } from 're-resizable';
import { get, map, size } from 'lodash'
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from '@material-ui/icons/ArrowBack';
import { color } from "react-native-elements/dist/helpers";
// Customizable Area End

export default class Cache2 extends Cache2Controller {
  global: any;
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDataFromChild = (data: any) => {
    // Do something with the data received from the child
    this.handleAdvanceSearchFunction(data)
    this.setState({ selectedMail: null })
  };
  normalDetailArray = (data: any) => {
    this.setState({ normalSelectSearchMail: data, selectedMail: "" });
  };
  handleLogout = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_password");
  };

  // Customizable Area End
  /* istanbul ignore next */
  getType = (isDark: boolean) => {
    return isDark ? "dark" : "light"
  }

  /* istanbul ignore next */
  getPaper = (isDark: boolean) => {
    return isDark ? "#0d0d0d" : "#fffff"
  }

  /* istanbul ignore next */
  renderUndoBox = (isDark: boolean) => {
    const { multipleFavArray } = this.state
    return <div style={{
      position: 'absolute',
      bottom: 10,
      background: isDark ? 'white' : 'black',
      color: isDark ? 'black' : 'white',
      padding: 10,
      left: '12%',
      width: '80%',
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <span>{multipleFavArray.length} email{multipleFavArray.length > 1 ? 's' : ''} favorited</span>
      <span onClick={this.UndoMulptileFavAPIFunction} style={{ paddingLeft: 50, cursor: 'pointer' }}>Undo</span>
    </div>
  }
  isMobileView = () => (typeof window !== 'undefined' ? window.innerWidth < 600 : false);


  emailsRender = () => map(this.state.emailList, ((item: any, index: any) => {
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;
    return (
      <div ref={this.emailRefs[index]} >
        <Box
          tabIndex={0}
          // key={item.attributes.save_set_id}
          style={{
            ...webStyle.mail_container,
            backgroundColor:
              this.state.selectedMail ===
                item.attributes.save_set_id
                ? themeStyle.mail_container
                  .backgroundColor
                : "",
                outline: "none"
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              selectedMail: item.attributes.save_set_id,
            }, () => {
              this.toggleDialog(true)
              document.activeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            });
          }}
          onKeyDown={(e: any) => this.handleKeyDown(e)} 
          data-test-id="inboxEmailsTest3"
          
        >
          <Box style={webStyle.center_container}>
            <Box
              style={{
                ...themeStyle.avatar_container,
                ...webStyle.avatar_container,
              }}
            >
              <Typography
                data-test-id="user_initials"
                style={{
                  fontSize: "11px",
                  position: "relative",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleMultipleFavEmails(
                    item.attributes.save_set_id,
                    item.attributes.vault_id
                  );
                }}
              >
                {item.attributes.author[0].toUpperCase()}
                {item.attributes.author[1].toUpperCase()}
                {this.state.multipleFavArray.filter(
                  (e: any) =>
                    e.save_set_id ==
                    item.attributes.save_set_id
                )?.length !== 0 && (
                    <div style={{
                      position: 'absolute',
                      left: 10,
                      bottom: -10
                    }}>
                      <CheckCircleSharpIcon style={{ width: 12, height: 12, color: isDark ? 'yellow' : '#0E127E' }} />
                    </div>
                  )}
              </Typography>
            </Box>
          </Box>
          <Box style={webStyle.info_container}>
            <Box style={webStyle.heading_container}>
              <Typography
                data-test-id="from_email"
                style={webStyle.title}
              >
                {
                  item.attributes.author}
              </Typography>
              <Typography data-test-id="email_formDate">
                {this.state.tabState == "Downloaded" ? this.formatDate(item.attributes.downloaded_date) : this.formatDate(item.attributes.sent_dt)}
              </Typography>
            </Box>
            <Typography style=
              {
                webStyle.eclipse_subject
              }
              data-test-id="email_subject"
            >
              {item.attributes.subject.charAt(0).toUpperCase() +
                item.attributes.subject.slice(1)
              }
            </Typography>
            <Typography
              style={
                webStyle.sub_heading
              }>
              {
                item.attributes.body}
            </Typography>
          </Box>
        </Box>
      </div>
    )
  }))

  filterComponent = () => {
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gridGap: "5px",
          }}
          data-test-id="filterPopOver"
        >
          <FilterList style={webStyle.icons} />
          <Box
            style={{ cursor: "pointer", fontSize: "14px" }}
            onClick={() => this.handlePopBtn("filter")}
            data-test-id="handlePopButton"
          >
            <Typography style={{fontSize: '14px'}}>
              Filter
            </Typography>
          </Box>
          {this.state.filterPopOpen && (
            <>
              <Backdrop
                style={{
                  background: "transparent",
                  zIndex: 1300,
                }}
                open={this.state.filterPopOpen}
                onClick={this.handleFilterPopClose}
                data-test-id="settingModal"
              >
                <FilterWeb
                  data-id='FilterWebID'
                  handleClose={this.handleFilterPopClose}
                  handleClear={this.handleClearFilters}
                  isDark={isDark}
                  handleApplyFilterBtn={this.handleApplyFilterBtn}
                  data-test-id="filterPopClose"
                  selectedFilter={this.state.filterItems}
                  otherDays={this.state.otherDayValue}
                  updateFilter={this.handleFilterChange}
                  checkDateBetween={this.checkDateBetween}
                  tabState={this.state.tabState}

                  resetData={this.handleClearFilters}
                />
              </Backdrop>
            </>
          )}

          {!!size(this.state.filterItems) &&
            <Box
              style={{
                fontSize: "10px",
                padding: "2px 3px",
                borderRadius: "4px",
                ...themeStyle.filter_count_color,
              }}
            >
              {size(this.state.filterItems)}
            </Box>
          }
        </Box>
      </Box>)
  }

  sortComponent = () => {
    const mode = this.context.theme;
    const isDark = mode === "dark";

    return (
    <Box sx={{ display: "flex", gridGap: "10px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gridGap: "5px",
        }}
      >
        <FilterList style={webStyle.icons} />
        <Box style={{ cursor: "pointer", fontSize: "14px" }}>
          <Box
            style={{minWidth: '120px'}}
            onClick={() => this.handlePopBtn("sort")}
            data-test-id="sortPopOver"
          >
            <Typography style={{fontSize: '14px'}}>
              Sort by :{" "}
              {this.state.sortTempOption ? (
                this.state.sortTempOption == "sent_dt" ? (
                  "Date"
                ) : (
                  this.state.sortTempOption
                )
                ) : (
                  <>
                    {this.state.selectedSortingOption === "" &&
                      "Default"}
                    {this.state.selectedSortingOption === "sent_dt"
                      ? "Date"
                      : this.state.selectedSortingOption}
                  </>
                )}
              </Typography>
            </Box>
            {this.state.sortPopOpen && (
              <>
                <Backdrop
                  style={{
                    background: "transparent",
                    zIndex: 1300,
                  }}
                  open={this.state.sortPopOpen}
                  onClick={() =>
                    this.setState({ sortPopOpen: false })
                  }
                  data-test-id="sortingwebBtn"
                >
                  <SortingWeb
                    handleClosePop={this.handleSortPopClose}
                    selected={this.state.selectedSortingOption}
                    handleSortingOptionChange={
                      this.handleSortingOptionChange
                    }
                    sort_direction={this.state.sort_direction}
                    isDark={isDark}
                    handleSortingFunction={
                      this.handleSortingFunction
                    }
                    toggleSortOrder={this.toggleSortOrder}
                    defaultBtnSort={this.defaultBtnSort}
                    selectedSortingOption={this.state.sort_column}
                  />
                </Backdrop>
              </>
            )}
          </Box>
        </Box>
      </Box>)
  }

  searchEmailButtonRender = () => {
    const mode = this.context.theme;
    const isDark = mode === "dark";

    return (  <Box
      sx={{
        display: "flex",
        marginTop: "20px",
        gridGap: "10px",
      }}
    >
    <div
      style={{
        backgroundImage: `url(${isDark ? darkReply : lightReply})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '36px',
        backgroundRepeat: "no-repeat",
        aspectRatio: "2.84",
        cursor: 'pointer'
      }}
      onClick={() => this.openOutLookReply(this.state.normalSelectSearchMail.attributes.sender_email)}
      data-test-id="openOutLookReplyNormal"
    >
    </div>

    <div
      style={{
        backgroundImage: `url(${isDark ? darkForward : lightForward})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '36px',
        backgroundRepeat: "no-repeat",
        aspectRatio: "3.4",
        cursor: 'pointer'
      }}
      onClick={() =>
        this.openOutLookForward(
          this.state.normalSelectSearchMail.attributes
            .sender_email,
          this.state.normalSelectSearchMail.attributes
            .subject,
          this.state.normalSelectSearchMail.attributes
            .body
        )
      }
      data-test-id="openOutLookForwardNormal"
      >
    </div>
    </Box>)
  }
  renderEmail = () => {
    const mode = this.context.theme;
    const { emailList, selectedMail } = this.state;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;
  return (
    emailList
      ?.filter((ele: any) => ele.attributes.save_set_id === selectedMail)
      .map((ele: any) => (
        <Box
        component='div'
          key={ele.attributes.save_set_id} 
          sx={{
            width: {
              xs: "90%",
              sm: '68%',
            },
            padding: "10px 5px 0px 0px",
            height: "fit-content",
            ...themeStyle.single_mail_cont,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <Typography style={{ fontWeight: 600, color:"black" }}>
              {ele.attributes.subject.charAt(0).toUpperCase() +
                ele.attributes.subject.slice(1)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "10%",
                ...themeStyle.icon_color,
              }}
            >
              <Box
                onClick={() => this.handleIndividualDownload(
                  ele.attributes.vault_id,
                  ele.attributes.save_set_id
                )}
                style={{ cursor: "pointer" }}
                data-test-id="IndividualDownloadFunction"
              >
                <Archive />
              </Box>
              <Box
                onClick={() => this.handleStarClick(ele)}
                style={{ cursor: "pointer" }}
                data-test-id="startClick"
              >
                {ele.attributes.is_favourite ? (
                  <Star />
                ) : (
                  <StarOutline />
                )}
              </Box>
              <MoreHoriz />
            </Box>
          </Box>
          <Divider style={{ width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              gridGap: "10px",
              marginTop: "20px",
            }}
          >
            {/* profile pic */}
            <Box
              sx={{
                ...webStyle.avatar_container,
                ...themeStyle.avatar_container,
              }}
            >
              <Typography style={{ fontSize: "11px" }}>
                {ele.attributes.author[0].toUpperCase() +
                  ele.attributes.author[1].toUpperCase()}
              </Typography>
            </Box>
            {/* userName */}
            <Box>
              <Typography style={{color:"black"}}>{ele.attributes.author}</Typography>
              <Typography style={{ fontSize: "0.7em", color: "#949598" }}>
                {moment(ele.attributes.sent_dt).format("ddd, DD MMM YYYY [at] h:mm A")}
              </Typography>
            </Box>
          </Box>
          {/* message content */}
          <Typography style={{ marginTop: "10px", fontSize: "15px", color:"black" }}>
            {ele.attributes.body}
          </Typography>
          <Divider style={{ marginTop: "10px" }} />
          {/* attachments */}
          <Box
            sx={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "5px",
              gridGap: "15px",
            }}
          >
            {/* <AttachFile /> */}
            {/* <Typography>{ele.attributes[8]}</Typography> */}
          </Box>
          {this.emailButtonRender(ele)}
        </Box>
      ))
  );
  }

  emailButtonRender = (ele: any) => {
    const mode = this.context.theme;
    const isDark = mode === "dark";

    return (<Box sx={
      {
        display: "flex", gridGap: "10px",
        marginTop: "20px",
      }
    }>
      <div
        style={{
          backgroundImage: `url(${isDark ? darkReply : lightReply})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: '36px',
          backgroundRepeat: "no-repeat",
          aspectRatio: "2.84",
          cursor: 'pointer'
        }}
        onClick={() => this.openOutLookReply(ele.attributes.sender_email)
        }
        data-test-id=
        "openOutLookReply"
      >
      </div>

      <div
        style={{
          backgroundImage: `url(${isDark ? darkForward : lightForward})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: '36px',
          backgroundRepeat: "no-repeat",
          aspectRatio: "3.4",
          cursor: 'pointer'
        }}
        onClick={() => this.openOutLookForward(ele.attributes.sender_email, ele.attributes.subject, ele.attributes.body)}
        data-test-id="openOutLookForward"
      >
      </div>

    </Box>)
  }
  sidebarContetRender = (mode: any, isDark: any, themeStyle: any, options: any ) => {
    const isMobile = this.isMobileView();
    return <Box sx={webStyle.container_child}>
    <Box
      sx={{
        height: "100vh",
        width: {
          xs: "200px",  
          sm: "13%",
        },
        ...themeStyle.bgColor,
        ...themeStyle.borderRight,
      }}
    >
      <Box
        sx={{
          margin: "auto",
          width: "fit-content",
        }}
      >
        <img
          src={isDark ? darkLogo : lightLogo}
          alt="company_logo"
          width="65px"
          height={"40px"}
          style={webStyle.logo}
        />
      </Box>
      <Box
        sx={{
          marginTop: "25px",
          textAlign: "center",
        }}
      >
        <Button
          style={{
            textTransform: "none",
            padding: "5px 8px",
            fontWeight: 500,
            width: "90%",
            justifyContent: "left",
            ...themeStyle.newBtnColor,
          }}
          data-test-id="composeEmailTest"
          startIcon={
            <MailOutline
              style={{
                marginLeft: "0.6em",
                fontSize: "1.2em",
                marginRight: "-0.1em",
              }}
            />
          }
          onClick={() => this.openOutlookCompose()}
        >
          New Email
        </Button>
      </Box>
      <Box style={{ marginLeft: '10px' }}>
        <Box
          sx={{
            margin: 'auto',
            display: "flex",
            width: "90%",
            marginTop: "30px",
            marginBottom: "30px",
            fontWeight: 400,
            alignItems: "center",
            gridGap: "9px",
            ...themeStyle.color,
            ...themeStyle.options,
          }}
        >
          <Box
            style={{
              paddingLeft: "13px",
              marginLeft: "-4px"
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
              width="16"
              height="16"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
              />
            </svg>
          </Box>
          <Box
          style={{marginLeft: '-5px'}}
            data-testId="carlysimonClick"
            onClick={() => {
              this.handleTabUpdate(this.state.username)
              this.toggleDrawer(false)();
            }}
          >
            <Typography style={{fontSize: '13px'}}>
              {this.state.username}
            </Typography>
          </Box>
        </Box>
        {options.map((ele: any, key: any) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              alignItems: "center",
              gridGap: "9px",
              width: "90%",
              margin: "auto",
              marginTop: "20px",
              fontWeight: 300,
              color:
                this.state.tabState === ele.title
                  ? themeStyle.color.color
                  : "",
              ...themeStyle.options,
            }}
          >
            <Box sx={{ paddingLeft: "13px", marginLeft: "-2px" }}>
              <Typography>{ele.icon}</Typography>
            </Box>
            <Box
              data-testId="eleTitle"
              sx={{
                padding: "10px 0px",
                marginLeft: "-3px",
              }}
              onClick={() => {
                this.handleTabUpdate(ele.title);
                this.toggleDrawer(false)(); 
              }}
              
            >
              <Typography style={{ fontSize: "13px" }}>
                {ele.title}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box style={{ marginLeft: "2.3em", marginTop: '8%' }}>
          {" "}
          <Button style={{paddingLeft: 0}} onClick={this.handleLogout}>Logout</Button>
        </Box>
      </Box>
    </Box>
  
  {!isMobile && this.renderLayout(isDark, themeStyle, options)}

    
  </Box>
  }

  renderLayout = (isDark: any, themeStyle: any, options: any) =>{
    const isMobile = this.isMobileView();
    return <Box sx={{
      width: {
        xs: '100%', 
        sm: '90%', 
      },
      padding: '0px',
    }}>
    <AppHeader
      {...this.props}
      open={false}
      navigation={""}
      id="1"
      isDark={isDark}
      searchData={{}}
      handleSearchTerm={() => { }}
      flag={this.state.flag}
      onDataReceived={this.handleDataFromChild}
      handleAdvanceSearchPop={this.handleAdvanceSearchPop}
      clearFilterAdvanceSearch={this.clearFilterAdvanceSearch}
      normalDetailArray={this.normalDetailArray}
    />
    <Box
      sx={{
        padding: "10px 0px",
        ...themeStyle.bgColor,
        ...themeStyle.borderBottom,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 10px",
          gridGap: "20px",
        }}
      >
        <Box sx={{
          display: {
            xs: 'block', 
            sm: 'none', 
          }
        }}>
        <IconButton onClick={this.toggleDrawer(true)} aria-label="open drawer">
            <MenuIcon />
          </IconButton>
        </Box>
        {this.sortComponent()}

        {this.filterComponent()}
      </Box>
    </Box>
    <Box style={webStyle.mail_det_wrapper}>

      <StyledResizable
        
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >

        <Box
          sx={{
            ...webStyle.mails_container,
            ...themeStyle.mails_container,
            height: {
              xs: '100vh',
              sm: '86vh',
            },
            overflow: 'scroll',
            borderRight: '0.5px solid rgb(243, 243, 253)',
            bgcolor: 'white',
          }}
          data-test-id="email_list_container"
          onScroll={() => {
            this.setState({isScrollFind: true});
          }}
        >

          <div data-test-id={"email_list"} style={{ position: 'relative', }} 
           
          >
            {/* // populate the all emails  // */}

            {this.emailsRender()}
            
            <div style={{
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
            }}
            ref={this.emailPageEndRef}
            >
              {this.state.isLoading && (
                 <Box component='div' sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingTop: '20px',
                 }}>
               <CircularProgress data-testid="loading-spinner" color="secondary" />
                 </Box>
            )}
              {!this.state.isLoading && !size(this.state.emailList) &&
                <Typography>
                  No data
                </Typography>}
            </div>
            {/* //////////////////////////////// */}
          </div>
          <div style={{ textAlign: "center" }} ref={this.emailPageEndRef}>
              <div style={{ textAlign: "center" }} ref={this.emailPageEndRef}>
          </div>
          </div>
        </Box>

        {this.state.isShowUndo && this.renderUndoBox(isDark)}
      </StyledResizable>
      {/* populate the single email */}
      {isMobile && 
          <Dialog
          fullScreen
          open={this.state.openDialog}
          onClose={() => this.toggleDialog(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" style={{background: 'white'}}>
    <IconButton
      edge="start"
      color="inherit"
      onClick={() => this.toggleDialog(false)}
      style={{
        padding: '0px 24px',
        color:"black"
      }}
      aria-label="close"
    >
      <ArrowBack />
    </IconButton>
  </DialogTitle>
          <DialogContent style={{ overflowY: 'scroll', maxHeight: '100vh', background: 'white' }}>
          {isMobile && this.state.multipleFavArray.length === 0 && this.state.emailList?.length > 0 && this.renderEmail()}
          </DialogContent>
          
        </Dialog>
      }
        {this.state.multipleFavArray.length === 0 && this.state.emailList?.length > 0 && this.renderEmail()}
      
      {this.state.multipleFavArray.length !== 0 && (
        <MultipleFavEmailsWeb
          isDark={isDark}
          length={this.state.multipleFavArray.length}
          MulptileFavAPIFunction={this.MulptileFavAPIFunction}
          CancelMultipleFav={this.CancelMultipleFav}
        />
      )}
      
    </Box>
        </Box>
  }


  render() {
    const getLoginDetails = localStorage.getItem('user_name') && localStorage.getItem('user_password');
    if(!getLoginDetails) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    const mode = this.context.theme;
    const isDark = mode === "dark";
    const themeStyle = isDark ? darkStyles : lightStyles;
    const options = [
      { icon: <Drafts style={webStyle.icons} />, title: "Draft" },
      { icon: <PresentToAll style={webStyle.icons} />, title: "Sent Items" },
      {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: "16" }}
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.6"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        ),
        title: "Downloaded",
      },
      { icon: <Star style={webStyle.icons} />, title: "Starred" },
    ];
    const isMobile = this.isMobileView();


    const theme = createTheme({
      palette: {
        primary: {
          main: "#fff",
          contrastText: "#fff",
        },
        secondary: {
          main: "#eaebff",
        },
        type: this.getType(isDark),

        background: {
          default: "#ffffff",
          paper: this.getPaper(isDark),
        },
      },
      typography: {
        subtitle1: {
          margin: "20px 0px",
        },
      },
    });



    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Paper
          style={{
            height: "100vh",
            width: "100vw",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <Container maxWidth={"xl"} style={webStyle.container}>
        {/* Sidebar visible on screens larger than 413px */}
          {!isMobile && this.sidebarContetRender(mode, isDark, themeStyle, options)}          
        
        {/* Button and Drawer for screens 413px or smaller */}
        {isMobile && <>
          <Drawer
            anchor="left"
            open={this.state.open}
            onClose={this.toggleDrawer(false)}
          >
            {this.sidebarContetRender(mode, isDark, themeStyle, options)}
          </Drawer>
          {this.renderLayout(isDark, themeStyle,options )}
          </>
          }
        
          
          </Container>
        </Paper>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
///////////////////////////////

/////////////////////////////////
const lightStyles = {
  bgColor: {
    backgroundColor: "#f6f7f9",
  },
  borderRight: {
    borderRight: "1px solid #efefef",
  },
  borderBottom: {
    borderBottom: "1px solid #efefef",
  },
  color: {
    color: "#0a2cf0", //deep blue
  },
  newBtnColor: {
    color: "#0d1384", //dark blue
    backgroundColor: "#eaebff",
  },
  options: {
    cursor: "pointer",
    fontSize: "13px",
  },
  filter_count_color: {
    backgroundColor: "#434343",
    color: "white",
    border: "2px solid #F5F202",
  },
  icon_color: {
    color: "#0a2cf0", //blue
  },
  mails_container: {
    borderRight: "0.5px solid #f3f3fd",
    backgroundColor: "white",
  },
  mail_container: {
    backgroundColor: "#cecfd5", //grey
  },
  single_mail_cont: {
    backgrounColor: "white",
  },
  avatar_container: {
    backgroundColor: "#7E8CCA",
  },
};
const darkStyles = {

  bgColor: {
    backgrounColor: "#202020",
    borderRight: "1px solid #2c2c2c",
  },
  borderBottom: {
    borderBottom: "1px solid #2c2c2c",
  },
  borderRight: {
    borderRight: "1px solid #2c2c2c",
  },
  color: {
    // color: "#fbf502",
    color: "yellow",
  },
  mails_container: {
    borderRight: "0.5px solid #242424",
  },
  mail_container: {
    backgroundColor: "#242424",
  },
  newBtnColor: {
    color: "#ffffff",
    backgroundColor: "#202020",
  },
  options: {
    cursor: "pointer",
    fontSize: "13px",
  },
  filter_count_color: {
    color: "#F5F202",
    border: "2px solid #F5F202",
  },
  icon_color: {
    // color: "#eee403", //yellow
    // color:"#8e9091"
    color: "yellow",
  },
  single_mail_cont: {
    // height : "min -content",
    // boxSizing : "border-box",
    backgrounColor: "#262626", //grey
    // paddingBottom :"8px",
    // paddingLeft: "10px",
  },
  avatar_container: {
    backgroundColor: "#2c2c2c",
  },
};
const webStyle = {
  container: {
    padding: "0px",
    width: "100%",
    height: "100%",
  },
  container_child: {
    display: "flex",
  },
  logo: {
    paddingTop: "10px",
  },
  icons: {
    fontSize: "15px",
    textAligh: "left",
    // color:"#8e9091"  //grey
  },
  checkCircle: {
    fontSize: "20px",
    marginRight: "5px",
  },
  options: {
    cursor: "pointer",
  },
  mail_det_wrapper: {
    display: "flex",
    gap: "10px",
  },
  center_container: {
    display: "flex",
  },
  mails_container: {
    // width: "30%",
    height: "86vh",
    overflow: "scroll",
    borderRight: "1px solid #2c2c2c",
    // scrollbarWidth: "none",
  },
  mail_container: {
    display: "flex",
    margin: "8px 0px",
    padding: "8px",
    borderLeft: "none",
    cursor: "pointer",
    borderColor: "#2c2c2c",
    // backgroundColor: "#2c2c2c",
  },
  heading_container: {
    width: "98%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color:"black"
    // background: "green",
  },

  info_container: {
    marginLeft: "8px",
    width: "100%",
  },
  title: {
    fontSize: "16px",
    color:"black"

  },
  eclipse_subject: {
    WebkitLineClamp: 1,
    textOverflow: 'ellipsis',
    lineHeight: '1.5rem', 
    maxHeight: '1.5rem', 
    width: '100%',
    fontSize: "16px",
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical' as 'vertical',
    color:"black"
  },
  sub_heading: {
    fontSize: "14px",
    color: "#a6a6a6",
    fontStyle: "italic",
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    lineHeight: '1.5rem', 
    maxHeight: '3rem', 
    width: '100%',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical' as 'vertical',
  },

  avatar_container: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#7e8cca",
    color: "white",
  },

  attachments_container: {
    borderRadius: "10px",
    backgroundColor: "#a9a9a9",
    padding: "5px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    border: "1px solid green",
  },
  reply_forword_btns: {
    display: "flex",
    textAlign: "center",
    padding: "5px 10px",
    border: "1px solid grey",
    borderRadius: "20px",
    gridGap: "5px",
    alignItems: "center",
    fontSize: "15px",
    cursor: "pointer",
  },
  sort_opt_box: {
    border: "1px solid red",
    position: "absolute",
    top: "14%",
    left: "23.5%",
    width: "21%",
    backgroundColor: "#f6f7f9",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  sort_opt: {
    display: "flex",
    gridGap: "5px",
    alignItems: "center",
    padding: "5px 10px",
    cursor: "pointer",
  },
  cancel_btn: {
    backgroundColor: "white",
    color: "#656565",
    borderRadius: "5px",
  },
  attachments: {
    border: "1px solid grey",
    padding: "5px",
  },
};
const StyledResizable = styled(Resizable)(({ theme }) => ({
  width: '30%', 
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '100%',
  },
  maxWidth: '30%', 
  minWidth: '30%',
}));

// Customizable Area End
