Object.defineProperty(exports, '__esModule', {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = 'application/json';
exports.getMethod = 'GET';
exports.validationApiMethodType = 'POST';
exports.getCachedEmailsApiEndpoint = 'bx_block_cache/caches';
exports.apiEndPointURL = 'bx_block_api_search/api_searches/search_data'
exports.apiDownloadURL = 'bx_block_api_search/api_searches/download'
exports.apiAddToFavoriteURL = 'bx_block_api_search/api_searches/toggle_favourites'
exports.apiFavListUrl = 'bx_block_api_search/api_searches/get_all_favourite_emails'
exports.apiEmailDetailURL = 'bx_block_api_search/api_searches/fetch_local_email'
exports.apiDownloadedEmailslURL = 'bx_block_api_search/api_searches/get_all_downloaded_emails'
exports.apiComposeEmailURL = 'bx_block_api_search/api_searches/compose_email'
exports.apiDeleteURL = 'apiDeleteURL'
exports.emailsCacheKey = 'emailsCacheKey';
exports.alertLabl = "Network Error";
exports.alertSubLabel = "Device not connected to the internet.";
exports.cancelLabel = "Cancel";
exports.attachmentsLabel = "Attachments";
exports.searchLabel = "Search...";
exports.alertRecommend = ''
exports.recommendations = ''
// Customizable Area End
