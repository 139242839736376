import React, { Component } from "react";
import "./SortingWeb.css";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Done as DoneIcon } from "@material-ui/icons";

interface Props {
  handleClosePop: () => void;
  handleSortingOptionChange: any;
  selected: any;
  sort_direction: any;
  isDark: any;
  handleSortingFunction: () => void;
  defaultBtnSort: () => void;
  toggleSortOrder: () => void;
  selectedSortingOption: any;
}
export default class SortingWeb extends Component<Props> {
  global: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    //const arrowColor = this.props.selected ? "currentColor" : "#C4C7CC";
    const mode = this.context.theme;
    const isDark = this.props.isDark;
    const themeStyles = isDark ? darkStyles : lightStyles;
    const theming = createTheme({
      palette: {
        primary: {
          main: "#fff",
        },
        secondary: { main: "#050505" },
        type: isDark ? "dark" : "light",
        background: {
          default: "#ffffff",
          paper: isDark ? "#0d0d0d" : "#fffff",
        },
      },
    });
    return (
      <ThemeProvider theme={theming}>
        <Grid className="grid-Part" style={{ ...themeStyles.Grid }} onClick = {(e)=>  e.stopPropagation()}>
          <Box>
            <Box className="headerPart">
              <Box style={{ fontWeight: 600 }}>Sort By</Box>
              <Box
                // onClick={this.props.clearSorting}
                onClick={this.props.defaultBtnSort}
                className="default_btn"
                data-test-id="clearSortBtn"
              >
                Default
              </Box>
              {/* <Box>{this.props.selected === "sent_dt" ? "Date" : this.props.selected}</Box> */}
              {/* <Box>{(this.props.selected === "") ? "Default" :
                      (this.props.selected === "sent_dt") ? "Date" :
                      this.props.selected}
                      </Box> */}
            </Box>
            <Box className="middleText">
              <Box
                className="middleText1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  // onClick={() => this.props.handleSortingOptionChange("Author")}
                  onClick={() => this.props.handleSortingOptionChange("Author")}
                >
                  <Typography
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      cursor: "pointer",
                      marginRight :"0.6em"
                    }}
                  >
                    Author
                  </Typography>
                </Box>
                <Box
                  data-test-id="toggle-sort-btn"
                  className="ArrowFlex"
                   onClick={this.props.toggleSortOrder}
                >
                  <Box className="ArrowFlex1">
                    {this.props.selected == "Author" &&
                    this.props.sort_direction == "asc" ? (
                      <Box style={{ display: "flex", gap: 0 }}>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ? "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Author"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Author" &&
                                    this.props.sort_direction == "asc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "Author"
                                ? "#C4C7CC"
                                : this.props.selected == "Author" &&
                                  this.props.sort_direction == "asc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Author"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Author" &&
                                    this.props.sort_direction == "asc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "Author"
                                ? "#C4C7CC"
                                : this.props.selected == "Author" &&
                                  this.props.sort_direction == "asc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          gap: -1,
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Author"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Author" &&
                                    this.props.sort_direction == "desc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "Author"
                                ? "#C4C7CC"
                                : this.props.selected == "Author" &&
                                  this.props.sort_direction == "desc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Author"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Author" &&
                                    this.props.sort_direction == "desc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "Author"
                                ? "#C4C7CC"
                                : this.props.selected == "Author" &&
                                  this.props.sort_direction == "desc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* } */}
                  <Box>
                    {this.props.selected === "Author" && (
                      <DoneIcon style={{ 
                        // marginLeft: "5em"  ,
                        color :this.props.isDark ? "yellow": "black"
                      }}  className ="authorDoneIcon"/>
                    )}

                  </Box>
                </Box>
              </Box>
              {/* ///////// */}

              {/* //////////////////////// */}
              <Box className="middleText1">
                <Box
                  onClick={() =>
                    this.props.handleSortingOptionChange("Subject")
                  }
                >
                  <Typography
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                  >
                    Subject
                  </Typography>
                </Box>
                <Box className="ArrowFlex" onClick={this.props.toggleSortOrder}>
                  <Box className="ArrowFlex1">
                    {this.props.selected == "Subject" &&
                    this.props.sort_direction == "asc" ? (
                      <Box style={{ display: "flex", gap: 0 }}>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ? "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Subject"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Subject" &&
                                    this.props.sort_direction == "asc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "Subject"
                                ? "#C4C7CC"
                                : this.props.selected == "Subject" &&
                                  this.props.sort_direction == "asc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Subject"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Subject" &&
                                    this.props.sort_direction == "asc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "Subject"
                                ? "#C4C7CC"
                                : this.props.selected == "Subject" &&
                                  this.props.sort_direction == "asc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          gap: -1,
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Subject"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Subject" &&
                                    this.props.sort_direction == "desc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "Subject"
                                ? "#C4C7CC"
                                : this.props.selected == "Subject" &&
                                  this.props.sort_direction == "desc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "Subject"
                                  ? "#C4C7CC"
                                  : this.props.selected == "Subject" &&
                                    this.props.sort_direction == "desc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "Subject"
                                ? "#C4C7CC"
                                : this.props.selected == "Subject" &&
                                  this.props.sort_direction == "desc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* } */}
                  {this.props.selected === "Subject" && (
                    <DoneIcon
                      style={{
                        // marginLeft: "5.1em" ,
                        color: this.props.isDark ? "yellow" : "black",
                      }}
                      className="subjectDoneIcon"
                    />
                  )}
                   {/* <DoneIcon
                      style={{
                        // marginLeft: "5.1em" ,
                        color: this.props.isDark ? "yellow" : "black",
                      }}
                      className="subjectDoneIcon"
                    /> */}
                </Box>
              </Box>
              {/* //////////////////////// */}
              <Box className="middleText1">
                <Box
                  onClick={() =>
                    this.props.handleSortingOptionChange("sent_dt")
                  }
                >
                  <Typography
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      cursor: "pointer",
                      marginRight :"20px"
                    }}
                  >
                    Date
                  </Typography>
                </Box>
                <Box className="ArrowFlex" onClick={this.props.toggleSortOrder}>
                  <Box className="ArrowFlex1">
                    {this.props.selected == "sent_dt" &&
                    this.props.sort_direction == "asc" ? (
                      <Box style={{ display: "flex", gap: 0 }}>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ? "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "sent_dt"
                                  ? "#C4C7CC"
                                  : this.props.selected == "sent_dt" &&
                                    this.props.sort_direction == "asc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "sent_dt"
                                ? "#C4C7CC"
                                : this.props.selected == "sent_dt" &&
                                  this.props.sort_direction == "asc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            //  stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "asc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "sent_dt"
                                  ? "#C4C7CC"
                                  : this.props.selected == "sent_dt" &&
                                    this.props.sort_direction == "asc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "sent_dt"
                                ? "#C4C7CC"
                                : this.props.selected == "sent_dt" &&
                                  this.props.sort_direction == "asc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          gap: -1,
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#C4C7CC":"#242020"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "sent_dt"
                                  ? "#C4C7CC"
                                  : this.props.selected == "sent_dt" &&
                                    this.props.sort_direction == "desc"
                                  ? "white"
                                  : "yellow"
                                : this.props.selected !== "sent_dt"
                                ? "#C4C7CC"
                                : this.props.selected == "sent_dt" &&
                                  this.props.sort_direction == "desc"
                                ? "#C4C7CC"
                                : "#242020"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        </Box>
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            // stroke={this.props.selected !== "Subject" ? "#C4C7CC" : (this.props.selected == "Subject" && this.props.sort_direction == "desc"  ) ?  "#242020" : "#C4C7CC"}
                            stroke={
                              this.props.isDark
                                ? this.props.selected !== "sent_dt"
                                  ? "#C4C7CC"
                                  : this.props.selected == "sent_dt" &&
                                    this.props.sort_direction == "desc"
                                  ? "yellow"
                                  : "white"
                                : this.props.selected !== "sent_dt"
                                ? "#C4C7CC"
                                : this.props.selected == "sent_dt" &&
                                  this.props.sort_direction == "desc"
                                ? "#242020"
                                : "#C4C7CC"
                            }
                            className="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* } */}
                  {this.props.selected === "sent_dt" && (
                    <DoneIcon
                      style={{
                        // marginLeft: "5.5em",
                        color: this.props.isDark ? "yellow" : "black",
                      }}
                      className="date-done-icon"
                    />
                  )}
                   {/* <DoneIcon
                      style={{
                        // marginLeft: "5.5em",
                        color: this.props.isDark ? "yellow" : "black",
                      }}
                      className="date-done-icon"
                    /> */}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: 0.5 }}>
            <button
              style={{
                marginRight: "0.5em",
                marginLeft: "0.6em",
                backgroundColor: "yellow",
                border: "1px solid yellow",
              }}
              className="btn"
              onClick={() => {
                this.props.handleSortingFunction();
              }}
              data-test-id="sortApplyTest"
            >
              Apply
            </button>
            <button
              className="btn"
              onClick={this.props.handleClosePop}
              style={{
                backgroundColor: this.props.isDark ? "grey" : "#fcfafa",
                color: "#aba4a4",
                border: "none",
              }}
            >
              Cancel
            </button>
          </Box>
        </Grid>
      </ThemeProvider>
    );
  }
}

const lightStyles = {
  Grid: {
    backgroundColor: "#ffffff",
  },
  text: {
    color: "black",
  },
};
const darkStyles = {
  Grid: {
    backgroundColor: "#0a0a0a",
    border: "1px solid white",
  },
  text: {
    color: "white",
  },
};
